import * as React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Container from "../components/container"

const SecondPage = () => (
  <Container>
    <SEO title="Thank You" />
    <h1 className="font-serif dark:text-slate-100 text-4xl mt-10 mb-5 font-bold underline">Form Submission Received</h1>
    <p className="my-4">Thanks for contacting McInnes Design. This form is currently only for testing purposed.</p>
    <Link to="/" className="hover:text-cyan-300">Go back to the homepage</Link>
  </Container>
)

export default SecondPage
